import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  GET_LIVE_MATCHES_URL,
  GET_MATCHES_URL,
  POOLING_TIME,
} from "../../constants";
import {
  LogoATP,
  LogoEstorilOpen,
  Placeholder,
  LogoJCDecauxDynamicContent,
} from "../Layout";
import {
  TournamentLiveMatches,
  Match,
  DailySchedule,
  MatchesResponse,
  Court,
} from "../../models";
import { LiveMatch } from "../MatchCard/LiveMatch";
import { ScheduleMatches } from "../MatchCard/ScheduleMatches";

export const MatchesPlaceholder: React.FC = () => {
  // To Debug
  const mainCourtId =
    Number(new URLSearchParams(window.location.search).get("court")) || 1;

  const forceSchedule = new URLSearchParams(window.location.search).has(
    "force-schedule"
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [liveMatch, setLiveMatch] = useState<Match>();
  const [todayMatches, setTodayMatches] = useState<Match[]>([]);
  const [courtName, setCourtName] = useState<string>("");

  const fetchMatches = async () => {
    const now: moment.Moment = moment();

    const today =
      new URLSearchParams(window.location.search).get("date") ||
      now.clone().format("YYYY-MM-DD");

    const response = await fetch(GET_MATCHES_URL);
    if (!response.ok) {
      throw new Error("Erro ao buscar dados da API");
    }
    const data: MatchesResponse = await response.json();
    if (data && data.DailySchedule) {
      const todaySchedule = data.DailySchedule.find(
        (dailySchedule: DailySchedule) => dailySchedule.IsoDate === today
      );

      if (todaySchedule && todaySchedule.Courts) {
        const court = todaySchedule.Courts.find(
          (court: Court) => court.CourtId === mainCourtId
        );

        setCourtName(court?.Name || "");

        const activeMatches: Match[] = [];
        court?.Matches?.forEach((match: Match) => {
          if (match && match.AssociationCode !== "WTA") {
            activeMatches.push(match);
          }
        });

        if (activeMatches) {
          setTodayMatches(activeMatches);
        }
      }
    }
  };

  const fetchLiveMatches = async () => {
    if (forceSchedule) {
      setLiveMatch(undefined);
      fetchMatches();
      return;
    }

    const response = await fetch(GET_LIVE_MATCHES_URL);
    if (!response.ok) {
      throw new Error("Erro ao buscar dados da API");
    }
    const data: TournamentLiveMatches = await response.json();

    if (data && data.TournamentMatches && data.TournamentMatches[0].Matches) {
      const match = data.TournamentMatches[0].Matches.find(
        (match) =>
          match.CourtId === mainCourtId &&
          !["F", "C", "W"].includes(match.Status) &&
          match.AssociationCode !== '"WTA"'
      );

      if (match) {
        setLiveMatch(match);
      } else {
        setLiveMatch(undefined);
        fetchMatches();
      }
    } else {
      setLiveMatch(undefined);
      fetchMatches();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLiveMatches();

    const interval = setInterval(() => {
      fetchLiveMatches();
    }, POOLING_TIME);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (liveMatch) {
    return (
      <div className="canvas live-match">
        <Placeholder />
        <LogoATP />
        <LogoEstorilOpen />
        <LogoJCDecauxDynamicContent />
        <LiveMatch liveMatch={liveMatch} />
      </div>
    );
  }

  if (todayMatches && todayMatches.length !== 0) {
    return (
      <div className="canvas results">
        <Placeholder />
        <LogoATP />
        <LogoEstorilOpen />
        <LogoJCDecauxDynamicContent />
        <ScheduleMatches matches={todayMatches} courtName={courtName} />
      </div>
    );
  }

  return <div className="canvas"></div>;
};
