import React, { useMemo } from "react";
import { Match, PlayerTeam, Team, Set } from "../../models";

export const ScheduleMatches: React.FC<{
  matches: Match[];
  courtName?: string;
}> = ({ matches, courtName }) => {
  const { finishedMatches, nextMatches } = useMemo(() => {
    matches.forEach((match) => {
      const splitChar = match.NotBeforeISOTime?.includes("+") ? "+" : "-";
      match.NotBeforeTime = match.NotBeforeISOTime?.split(splitChar)[0] || "";

      //check if have live match on schedule
      if (match.Live) {
        match.PlayerTeam1 = match.Live.PlayerTeam1;
        match.PlayerTeam2 = match.Live.PlayerTeam2;
      }

      // check won
      match.PlayerTeam1?.Sets.forEach((player1Set: Set) => {
        match.PlayerTeam2?.Sets.forEach((player2Set: Set) => {
          if (player1Set.SetNumber === player2Set.SetNumber) {
            if (Number(player1Set.SetScore) > Number(player2Set.SetScore)) {
              player1Set.SetWon = true;
            } else if (
              Number(player1Set.SetScore) < Number(player2Set.SetScore)
            ) {
              player2Set.SetWon = true;
            }
          }
        });
      });

      // check winner team
      if (match.PlayerTeam1?.Sets && match.PlayerTeam2?.Sets) {
        const winsTeam1 = match.PlayerTeam1?.Sets.filter(
          (item) => item.SetWon
        ).length;
        const winsTeam2 = match.PlayerTeam2?.Sets.filter(
          (item) => item.SetWon
        ).length;

        match.PlayerTeam1.Winner = winsTeam1 > winsTeam2;
        match.PlayerTeam2.Winner = winsTeam2 > winsTeam1;
      }

      // check if is double game
      match.isDoubles =
        match.TeamsInMatch[0].Players.length === 2 &&
        match.TeamsInMatch[1].Players.length === 2;
    });

    const finishedMatches: Match[] = [];
    const nextMatches: Match[] = [];
    const purgatoryMatches: Match[] = [];

    matches.forEach((match) => {
      if (match.PlayerTeam1?.Winner || match.PlayerTeam2?.Winner) {
        finishedMatches.push(match);
        // not has sets results but have result string
      } else if (match.ResultString && match.ResultString !== "") {
        purgatoryMatches.push(match);
      } else {
        nextMatches.push(match);
      }
    });

    // get max visible matches
    const maxVisibleMatches =
      [...finishedMatches.slice(-3), ...nextMatches.slice(-3)].filter(
        (match) => match.isDoubles
      ).length >= 2
        ? 2
        : 3;

    // add last finished Matches
    const visibleFinishedMatches: Match[] = [
      ...finishedMatches.slice(-maxVisibleMatches),
    ];

    // remove one of finish matches if necessary
    if (
      nextMatches.length > 0 &&
      visibleFinishedMatches.length !== 1 &&
      visibleFinishedMatches.length <= maxVisibleMatches
    ) {
      visibleFinishedMatches.shift();
    }

    // add extra possition with next matches
    let visibleNextMatches: Match[] = [
      ...nextMatches.slice(
        0,
        maxVisibleMatches - visibleFinishedMatches.length
      ),
    ];

    return {
      finishedMatches: visibleFinishedMatches,
      nextMatches: visibleNextMatches,
    };
  }, [matches]);

  return (
    <>
      <h1 className="page-title">
        Programação <span>&amp;</span> Resultados
      </h1>
      <div className="matches">
        {finishedMatches &&
          finishedMatches.map((match) => (
            <>
              {match.PlayerTeam1 && match.PlayerTeam2 && (
                <div className="match">
                  <TeamCard player={match.PlayerTeam1} />
                  <TeamCard player={match.PlayerTeam2} />
                </div>
              )}
            </>
          ))}

        {nextMatches &&
          nextMatches.map((match) => (
            <>
              {match.TeamsInMatch && match.TeamsInMatch.length === 2 && (
                <div className="match upcoming">
                  <UpcamingTeamCard team={match.TeamsInMatch[0]} />
                  <UpcamingTeamCard team={match.TeamsInMatch[1]} />
                  <div className="upcoming-panel">
                    <div className="next">
                      {match.NotBeforeTime !== ""
                        ? match.NotBeforeTime
                        : "A seguir"}
                    </div>
                    <div className="stadium">
                      {courtName || "Estádio Millenium"}
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    </>
  );
};

const UpcamingTeamCard: React.FC<{
  team: Team;
}> = ({ team }) => {
  return (
    <div className="team">
      <div className="players">
        {team.Players.map((player, index) => (
          <div className="player" key={`player-${player.PlayerId}`}>
            {index !== 0 && <span className="player-amp">&amp;</span>}
            <span className="player-first-name">{player.FirstName}</span>
            <span className="player-last-name">{player.LastName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const TeamCard: React.FC<{
  player: PlayerTeam;
}> = ({ player }) => {
  const isSingle = player.PartnerFirstName === "";

  return (
    <div className={`team ${player.Winner ? "winner" : "loser"}`}>
      <div className="players">
        <div className="player player-one">
          <span className="player-initial">{player.PlayerFirstName}</span>
          <span className="player-first-name">
            {player.PlayerFirstNameFull}
          </span>
          <span className="player-last-name">{player.PlayerLastName}</span>
        </div>
        {!isSingle && (
          <div className="player player-two">
            <span className="player-amp">&amp;</span>
            <span className="player-initial">{player.PartnerFirstName}</span>
            <span className="player-first-name">
              {player.PartnerFirstNameFull}
            </span>
            <span className="player-last-name">{player.PartnerLastName}</span>
          </div>
        )}
      </div>

      <ScoreCard sets={player.Sets} />
    </div>
  );
};

const ScoreCard: React.FC<{
  sets: Set[];
}> = ({ sets }) => {
  return (
    <div className="team-score">
      {sets.map((set) => (
        <div
          className={`number set ${set.SetWon ? "won" : ""}`}
          key={`set-${set.SetNumber}`}
        >
          <span>{set.SetScore}</span>
          {set.TieBreakScore && (
            <div className="tie-break">({set.TieBreakScore})</div>
          )}
        </div>
      ))}
    </div>
  );
};
