export const POOLING_TIME: number = 5 * 1000;
/*
export const GET_MATCHES_URL =
  "https://estorilopen-stage.herokuapp.com/api/matches";
export const GET_LIVE_MATCHES_URL =
  "https://estorilopen-stage.herokuapp.com/api/live-matches";
*/

export const GET_MATCHES_URL = "https://millenniumestorilopen.com/api/matches";
export const GET_LIVE_MATCHES_URL =
  "https://millenniumestorilopen.com/api/live-matches";
