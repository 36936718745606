import React from "react";
import { ReactComponent as EstorilOpenLogo } from "../img/logo-estoril-open-01.svg";
import { ReactComponent as JCDecauxDynamicContentLogo } from "../img/logo-jc-decaux-dynamic-content-01.svg"
import { ReactComponent as ATPLogo } from '../img/logo-atp-light-01.svg';

export const Header: React.FC = () => {
  return <header id="header" className="[  ]"></header>;
};

export const Footer: React.FC = () => {
  return <footer id="footer" className="[  ]"></footer>;
};

export const Placeholder: React.FC = () => {
  return <div className="placeholder"></div>;
};
export const LogoEstorilOpen: React.FC = () => {
  return (
    <div className="estoril-open-logo">
      <EstorilOpenLogo />
    </div>
  );
};
export const LogoATP: React.FC = () => {
  return (
    <div className="atp-logo">
      <ATPLogo />
    </div>
  );
};
export const LogoJCDecauxDynamicContent: React.FC = () => {
  return (
    <div className="jc-decaux-dynamic-content-logo">
      <JCDecauxDynamicContentLogo />
    </div>
  );
};

