import React, { useMemo } from "react";
import { Match, PlayerTeam, Set } from "../../models";

export const LiveMatch: React.FC<{ liveMatch: Match }> = ({ liveMatch }) => {
  const match: Match = useMemo(() => {
    // Check Won
    liveMatch.PlayerTeam1?.Sets.forEach((player1Set: Set) => {
      liveMatch.PlayerTeam2?.Sets.forEach((player2Set: Set) => {
        if (player1Set.SetNumber === player2Set.SetNumber) {
          if (player1Set.SetScore > player2Set.SetScore) {
            player1Set.SetWon = true;
          } else if (player1Set.SetScore < player2Set.SetScore) {
            player2Set.SetWon = true;
          }
        }
      });
    });

    // Check active Set
    if (liveMatch.Status !== "F") {
      if (liveMatch.PlayerTeam1?.Sets) {
        liveMatch.PlayerTeam1.Sets[
          liveMatch.PlayerTeam1.Sets.length - 1
        ].IsActive = true;
      }
      if (liveMatch.PlayerTeam2?.Sets) {
        liveMatch.PlayerTeam2.Sets[
          liveMatch.PlayerTeam2.Sets.length - 1
        ].IsActive = true;
      }
    }

    return liveMatch;
  }, [liveMatch]);

  return (
    <>
      <div className="page-title">Jogo a decorrer</div>
      <div className="metadata">
        <div className="court">
          <div className="icon icon-court"></div>
          <div className="text court-name">
            {match.CourtName || "Estádio Millennium"}
          </div>
        </div>
        <div className="timer">
          <div className="icon icon-timer"></div>
          <div className="text timer-value">{match.MatchTime || ""}</div>
        </div>
      </div>
      <div className={`match ${match.Status === "F" ? "finished" : ""}`}>
        {match.PlayerTeam1 && (
          <PlayerName
            player={match.PlayerTeam1}
            playerOrder={0}
            serving={match.Serve === "0"}
          />
        )}
        {match.PlayerTeam2 && (
          <PlayerName
            player={match.PlayerTeam2}
            playerOrder={1}
            serving={match.Serve === "1"}
          />
        )}
      </div>
    </>
  );
};

const PlayerName: React.FC<{
  player: PlayerTeam;
  playerOrder: number;
  serving: boolean;
}> = ({ player, playerOrder, serving }) => {
  const isSingle = player.PartnerFirstName === "";

  // TODO: serving on doubles
  return (
    <div className="player">
      {playerOrder === 1 && (
        <ScoreCard
          sets={player.Sets}
          gamePoints={player?.GamePointsPlayerTeam || "0"}
        />
      )}
      <div className={`player-name ${serving && "serving"}`}>
        {isSingle && (
          <div className="player-first-name">{player.PlayerFirstNameFull}</div>
        )}
        <div className="player-last-name">
          {!isSingle && (
            <span className="player-inicial-name">
              {player.PlayerFirstName}
            </span>
          )}
          <span>{player.PlayerLastName}</span>
        </div>
      </div>

      {!isSingle && (
        <div className="player-name">
          {isSingle && (
            <div className="player-first-name">
              {player.PartnerFirstNameFull}
            </div>
          )}
          <div className="player-last-name">
            {!isSingle && (
              <span className="player-inicial-name">
                {player.PartnerFirstName}
              </span>
            )}
            <span>{player.PartnerLastName}</span>
          </div>
        </div>
      )}

      {playerOrder === 0 && (
        <ScoreCard
          sets={player.Sets}
          gamePoints={player?.GamePointsPlayerTeam || "0"}
        />
      )}
    </div>
  );
};

const ScoreCard: React.FC<{
  sets: Set[];
  gamePoints: string;
}> = ({ sets, gamePoints }) => {
  return (
    <div className="player-score">
      {sets.map((set) => (
        <div
          className={`number set ${set.SetWon ? "won" : ""} ${
            set.IsActive ? "active" : ""
          }`}
        >
          <span>{set.SetScore}</span>
          {set.TieBreakScore && (
            <div className="tie-break">({set.TieBreakScore})</div>
          )}
        </div>
      ))}
      <div className="current-set number">{gamePoints}</div>
    </div>
  );
};
